import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const PAGE_TITLE = "Women’s and Youth Empowerment Initiative"
const PAGE_NAME = "womenAndYouth"
const WomensAndYouthPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
        <div className="row">
          <div className="col-sm-6">
            <p>
              <br />
              <br />
              <br />
              <br />
              This project aims to improve the educational, healthy, social and
              economical life of disadvantaged children ex-combatants and women
              survival of sexual violence in the Eastern of Congo. This
              initiative is among youth civic engagement projects of AJVDC Youth
              Volunteer peace group. <br />
              <br />
              Specific objectives are to empower these youth and women and to
              inculcate entrepreneurial skills in them, to make them
              self-employed, and initiate them to be engaged in non-violent
              activities, in sporting activities, in small business
              (agriculture, fishing, micro –credit revolving fund), vocational
              skills training and other similar innovative and creative
              projects.
            </p>
          </div>

          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"women47"} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"women56"} />
          </div>

          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"women74249"} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default WomensAndYouthPage
